<template>
	<PopupLayer class="size-px-16">
		<template
			v-slot:body
		>
			<div
				class="width-500 ma-auto radius-20"
			>
				<div class="pa-13-30 bg-mafia-blue flex-row justify-space-between">
					<h5 class="color-white">구독 게시판 </h5>
					<img
						@click="$emit('cancel')"
						class="cursor-pointer"
						:src="require('@/assets/image/web/notify_cartel/ico_close.svg')"
					/>
				</div>

				<div class="bg-white pa-30">
					<div>
						<label class="h5 color-333" for="cartelDesc">{{  $language.subscribe.title_subscribe_input_name }} <!-- 구독 게시판 이름 --></label>
						<div
							class="mt-16 radius-20 flex-row justify-space-between items-center"
							style="border:1px solid #dadada"
						>
							<input
								v-model="item_board_config.board_name"
								type="text" id="cartelDesc" :placeholder="$language.notice.name_require" maxlength="20"
								class="flex-1 pa-10-20"
							>

							<v-icon
								v-if="item_board_config.board_name"
								@click="item_board_config.board_name = ''"
								class="mr-10 size-px-20 color-dadada"
							>mdi-close-circle</v-icon>

							<span class="mr-20 size-px-14 color-bbb">{{ item_board_config.board_name.length }}/20</span>
						</div>
					</div>

					<div class="mt-20">
						<div class="flex-row justify-space-between items-center">
							<label class="h5 color-333" for="">{{  $language.subscribe.title_subscribe_permission_admin }} <!-- 운영자 권한 설정 --></label>
							<div class="toggle-group h_unset">
								<span
									class="btn-toggle " :class="{ on: item_board_config.admin_subscrp_board_mangement_authority_fg == 'Y'}"
									@click="item_board_config.admin_subscrp_board_mangement_authority_fg = item_board_config.admin_subscrp_board_mangement_authority_fg == 'Y' ? 'N' : 'Y' "
								><em class="hide">{{  $language.common.join_confirm.name }}</em></span>
							</div>
						</div>
						<div class="mt-10 color-888 size-px-14">
							{{  $language.subscribe.txt_subscribe_permission_admin }}
							<!-- 운영자에게 카르텔 구독 게시판(등록, 수정, 삭제)에 대한 권한을 줍니다. -->
						</div>
					</div>

					<hr class="under-line mt-20">

					<div class="mt-20">

						<div class="flex-row justify-space-between items-center">
							<label class="h5 color-333" for="">{{  $language.subscribe.title_subscribe_permission_open }} <!-- 구독 게시판 공개 설정 --> </label>
							<div class="toggle-group h_unset">
								<span
									class="btn-toggle " :class="{ on: item_board_config.board_release_fg == 'Y'}"
									@click="item_board_config.board_release_fg = item_board_config.board_release_fg == 'Y' ? 'N' : 'Y' "
								><em class="hide">{{  $language.common.join_confirm.name }}</em></span>
							</div>
						</div>
						<div class="mt-10 color-888 size-px-14">
							{{  $language.subscribe.txt_subscribe_permission_open}}
							<!-- 카르텔 가입된 회원에게만 게시판을 공개합니다 -->
						</div>
					</div>

					<hr class="under-line mt-20">

					<div class="mt-20 text-center">
						<button
							class="btn-inline btn-primary radius-20 pa-10-30"
							@click="postBoardConfig"
						>완료</button>
					</div>
				</div>
			</div>
		</template>
	</PopupLayer>
</template>

<script>
import PopupLayer from "@/view/Layout/PopupLayer";
export default {
	name: 'SubscribeSetting'
	, components: {PopupLayer}
	, props: ['user', 'item']
	, data: function(){
		return {
			program: {
				name: this.$language.notice.add
				, title: this.$language.notice.add
				, not_footer: true
				, not_header: true
				, type: 'cartel_sub'

			}
			, item_cartel: {}
			, item_board_config: {
				board_name: ''
				, cartl_number: this.$route.params.idx
				, member_number: this.user.member_number
				, board_release_fg: 'Y'
				, admin_subscrp_board_mangement_authority_fg: 'Y'
			}
		}
	}
	, computed: {
		is_save: function(){
			let t = true
			if(this.item_board_config.board_name != ''){
				t = false
			}

			return t
		}
		, text_title: function(){
			let t = this.program.title
			if(this.$route.params.b_id){
				t = this.$language.notice.edit
			}
			return t
		}
	}
	, methods: {
		getCartel: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_cartel_info
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx
					}
					, type: true
				})

				if(result.success){
					this.item_cartel = result.data
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}

		, getBoardConfig: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_board_config
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx
						, board_number: this.item.board_number
					}
					, type: true
				})
				if(result.success){
					this.item_board_config = result.data
					this.$set(this.item_board_config, 'admin_subscrp_board_mangement_authority_fg', this.item_board_config.board_authority.admin_authority_fg)
					this.$set(this.item_board_config, 'board_release_fg', this.item_board_config.release_fg)
					this.$set(this.item_board_config, 'board_number', this.item.board_number)
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, updateBoardConfig: async function(){

		}
		, postBoardConfig: async function(){
			try{
				if(!this.item_board_config.board_name){
					throw this.$language.notice.name_require
				}
				this.$bus.$emit('on', true)
				let url = this.$api_url.api_path.post_subscribe_request
				if(this.item_board_config.board_number){
					url = this.$api_url.api_path.post_subscribe_modify
				}
				const result = await this.$Request({
					method: 'post'
					, url: url
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx
						, board_name: this.item_board_config.board_name
						, board_number: this.item_board_config.board_number
						, board_release_fg: this.item_board_config.board_release_fg
						, admin_subscrp_board_mangement_authority_fg: this.item_board_config.board_release_fg
					}
					, type: true
				})
				if(result.success){
					this.$emit('click')
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
				// this.permission = this.resetPermission
			}
		}
	}
	, async created() {

		// await this.getCartel()
		if(this.item.board_number){
			await this.getBoardConfig()
		}
	}
}
</script>

<style>
.color-primary { color: var(--blue01) !important;}
</style>